<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { FhSelect } from '@fareharbor-com/beacon-vue';
import { FwbPagination } from 'flowbite-vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import type { OptionsType } from '@/types/SelectTypes';

interface ItemListPaginationProps {
  pageStart: number;
  pageEnd: number;
  totalItemsCount: number;
  showCount: boolean;

  showPagination: boolean;
  totalPagesCount: number;
  currentPage: number;

  currentPageSize: number | string;
  pageSizeOptions: OptionsType[];
}

const props = defineProps<ItemListPaginationProps>();
const emit = defineEmits<{
  pageChanged: [pageNumber: number];
  pageSizeChanged: [pageSize: number];
}>();

const showItemsCount = computed(
  () => props.showCount && props.totalItemsCount > 0,
);

const showPaginationComponent = computed(
  () => props.showPagination && props.totalPagesCount > 1,
);

const selectedPageSize = ref(props.currentPageSize);

watch(
  () => props.currentPageSize,
  (newVal) => {
    selectedPageSize.value = newVal;
  },
);

const pageSizeOptionsComputed = computed(() => {
  const options = [...props.pageSizeOptions];
  const selectedValue = selectedPageSize.value.toString();

  const exists = options.some(
    (option) => option.value.toString() === selectedValue,
  );

  if (!exists) {
    options.push({
      value: selectedValue,
      label: selectedValue,
    });
  }

  return options.sort((a, b) => Number(a.value) - Number(b.value));
});
</script>

<template>
  <BaseFlexbox
    justifyContent="space-between"
    alignItems="center"
    class="w-full"
  >
    <BaseFlexbox alignItems="center" class="mx-2">
      <div
        v-show="showItemsCount"
        class="flex-grow-0"
        data-test-id="item-count-info-footer"
      >
        Page
        <span class="font-bold">{{ pageStart }}-{{ pageEnd }}</span>
        of
        <span class="font-bold">{{ totalItemsCount }}</span>
      </div>

      <BaseFlexbox
        justifyContent="space-between"
        alignItems="center"
        class="mx-2"
      >
        <span class="mr-1">Showing</span>
        <FhSelect
          v-model="selectedPageSize"
          :options="pageSizeOptionsComputed"
          data-test-id="current-page-size"
          class="px-5"
          @update:modelValue="(value: number) => emit('pageSizeChanged', value)"
        ></FhSelect>
        <span class="ml-2">items</span>
      </BaseFlexbox>
    </BaseFlexbox>

    <div class="flex justify-end flex-grow">
      <FwbPagination
        v-show="showPaginationComponent"
        show-icons
        :show-labels="false"
        :total-pages="totalPagesCount"
        :modelValue="currentPage"
        @pageChanged="emit('pageChanged', $event)"
        data-test-id="pagination-section"
        class="pagination-light-mode"
      />
    </div>
  </BaseFlexbox>
</template>
<style scoped>
::v-deep .pagination-light-mode button {
  background-color: white !important;
  color: black !important;
  border-color: #d1d5db !important;
  transition:
    background-color 0.3s,
    color 0.3s;
}

::v-deep .pagination-light-mode button:hover:not(.disabled) {
  background-color: #f3f4f6 !important;
}

::v-deep .pagination-light-mode button[aria-current='page'],
::v-deep .pagination-light-mode button.text-blue-600.bg-blue-50 {
  background-color: #2563eb !important;
  color: white !important;
}

::v-deep .pagination-light-mode button[aria-current='page']:hover,
::v-deep .pagination-light-mode button.text-blue-600.bg-blue-50:hover {
  background-color: #1e40af !important;
  color: white !important;
}

::v-deep .pagination-light-mode button.disabled {
  background-color: #e5e7eb !important;
  color: #9ca3af !important;
  cursor: not-allowed !important;
}
</style>
