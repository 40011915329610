<script setup lang="ts" generic="TColumnKey extends string">
import { computed } from 'vue';
import type { Component } from 'vue';
import { useRouter } from 'vue-router';
import { FhLink } from '@fareharbor-com/beacon-vue';
import { Logger } from '@/common/Logger'; 

interface Props<TColumnKey extends string> {
  row: Record<TColumnKey, string | number> & { itemId?: number };
  value?: string | number;
  renderCell?: Component;
}

const props = defineProps<Props<TColumnKey>>();
const router = useRouter();


const itemUrl = computed(() => {
  return props.row.itemId ? `/items/${props.row.itemId}` : '/items/undefined';
});

/**
 *
 * @param event 
 * @param itemId 
 */

function navigateToItem(event: MouseEvent, itemId?: number) {
  if (itemId === undefined) {
    Logger.debug("itemId is undefined. Navigation will lead to /items/undefined.");
    return;
  }

  const isModifiedClick =
    event.metaKey || 
    event.ctrlKey || 
    event.shiftKey || 
    event.altKey ||  
    event.button !== 0; 

  if (isModifiedClick) {
    return; 
  }

  event.preventDefault(); 

  router.push(`/items/${itemId}`); 
}
</script>

<template>
  <td v-if="renderCell">
    <FhLink
      variant="plain"
      class="cursor-pointer !no-underline h-[100px] w-full"
      :href="itemUrl"
      @click="navigateToItem($event, row.itemId)"
      rel="noopener noreferrer"
    >
      <component :is="renderCell" :row="row" />
    </FhLink>
  </td>

  <td
    v-else
    class="whitespace-nowrap px-2 py-4 overflow-hidden"
    spellcheck="false"
  >
    <FhLink
      variant="plain"
      class="cursor-pointer !no-underline h-[100px]"
      :href="itemUrl"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ value }}
    </FhLink>
  </td>
</template>