<script setup lang="ts">
import {
  type ComputedRef,
  computed,
  inject,
  nextTick,
  onMounted,
  ref,
} from 'vue';
import {
  FhButton,
  FhIcon,
  FhText,
  FhTooltip,
  FhSelect,
} from '@fareharbor-com/beacon-vue';
import { download } from '@fareharbor-com/beacon-vue/icons';
import { initFlowbite, initTooltips } from 'flowbite';
import { Logger } from '@/common/Logger';
import { type GetItemsCSVResponse, getItemsCSV } from '@/common/api/getItems';
import {
  DEFAULT_OFFERED_SINCE_VARIANT,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_BY,
  PAGE_SIZE_OPTIONS,
  QueryParamName,
  SORT_BY_OPTIONS,
  SortByOption,
} from '@/common/constants/items';
import { saveFile } from '@/common/files';
import { makeCSVFilename } from '@/common/makeCSVFilename';
import { parsePositiveInt } from '@/common/parsePositiveInt';
import type { TrackingService } from '@/common/tracking/TrackingService';
import { transformItemsToTableData } from '@/common/transformItems';
import ItemListFilters from '@/components/itemList/ItemListFilters.vue';
import ItemListFooter from '@/components/itemList/ItemListFooter.vue';
import { ITEM_LIST_FILTERS_FEATURE_FLAGS } from '@/components/itemList/features';
import { itemListColumnConfig } from '@/components/itemList/itemListColumnConfig';
import { OfferedSinceOptionVariant } from '@/components/itemList/types';
import DeskTable from '@/components/shared/DeskTable/DeskTable.vue';
import type { TableData } from '@/components/shared/DeskTable/types';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { type QueryParams, useQueryParams } from '@/composables/queryParams';
import { trackingKey } from '@/plugins/keys';
import { useItemFiltersStore } from '@/stores/itemFilters';
import { useItemsStore } from '@/stores/items';
import { useSearchStore } from '@/stores/search';
import { useAuthStore } from '@/stores/useAuthStore';

const authStore = useAuthStore();
const itemsStore = useItemsStore();
const searchStore = useSearchStore();
const itemFiltersStore = useItemFiltersStore();

const { getAllQueryParams, updateMultipleQueryParams } = useQueryParams();

const currentPage = ref(DEFAULT_PAGE);
const currentPageSize = ref(DEFAULT_PAGE_SIZE);
const currentSortBy = ref(DEFAULT_SORT_BY);
const areFiltersApplied = ref(true);

// @ts-ignore
// computed properties
const itemsTableData: ComputedRef<TableData> = computed(() => {
  const items = itemsStore.itemsByPage(currentPage.value);
  return transformItemsToTableData(items);
});

const pageStart: ComputedRef<number> = computed(
  () => (currentPage.value - 1) * currentPageSize.value + 1,
);

const pageEnd: ComputedRef<number> = computed(() => {
  const calculatedPageEnd = pageStart.value + currentPageSize.value - 1;
  if (calculatedPageEnd > itemsStore.totalCount) return itemsStore.totalCount;
  return calculatedPageEnd;
});

const totalPagesCount: ComputedRef<number> = computed(() =>
  Math.ceil(itemsStore.totalCount / currentPageSize.value),
);

const tracking = inject<TrackingService>(trackingKey);

const CSV_FILENAME = {
  MARKETPLACE: 'marketplace-items',
  MARKETPLACE_BOOKMARKED: 'marketplace-bookmarked-items',
} as const;

function setCurrentPage(page: string | null) {
  if (page) {
    currentPage.value = Number.parseInt(page, 10);
    if (itemsStore.totalCount === 0) {
      currentPage.value = DEFAULT_PAGE;
    } else {
      const lastPage = Math.ceil(itemsStore.totalCount / currentPageSize.value);
      if (currentPageSize.value !== null && currentPage.value > lastPage) {
        currentPage.value = lastPage;

        // Update the URL to reflect the corrected page
        const url = new URL(window.location.href);
        url.searchParams.set(QueryParamName.PAGE, lastPage.toString());
        window.location.href = url.toString(); // Redirect to corrected URL
      }
    }
  } else {
    currentPage.value = DEFAULT_PAGE;
  }
}

function readQueryParams() {
  const params = getAllQueryParams();

  const pageSizeParam = params[QueryParamName.PAGE_SIZE] || null;
  const parsedPageSize = parsePositiveInt({
    value: pageSizeParam,
    fallback: DEFAULT_PAGE_SIZE,
    min: 1,
    max: 100,
  });

  if (
    parsedPageSize === DEFAULT_PAGE_SIZE &&
    pageSizeParam &&
    pageSizeParam !== DEFAULT_PAGE_SIZE.toString()
  ) {
    const url = new URL(window.location.href);
    url.searchParams.delete(QueryParamName.PAGE_SIZE);
    window.history.replaceState({}, '', url.toString());
  }
  currentPageSize.value = parsedPageSize;

  const pageParam = params[QueryParamName.PAGE] || null;
  const parsedPage = parsePositiveInt({
    value: pageParam,
    fallback: DEFAULT_PAGE,
    min: 1,
  });
  if (
    parsedPage === DEFAULT_PAGE &&
    pageParam &&
    pageParam !== DEFAULT_PAGE.toString()
  ) {
    const url = new URL(window.location.href);
    url.searchParams.delete(QueryParamName.PAGE);
    window.history.replaceState({}, '', url.toString());
  }
  setCurrentPage(parsedPage.toString());

  const sortByParam = params[QueryParamName.SORT_BY] || null;
  currentSortBy.value = sortByParam
    ? Number.parseInt(sortByParam, 10)
    : DEFAULT_SORT_BY;

  const includePhotos = params[QueryParamName.INCLUDE_PHOTOS];
  itemFiltersStore.includePhotos = !includePhotos || includePhotos === 'true';

  const offeredSinceVariant = params[QueryParamName.OFFERED_SINCE] as
    | number
    | null;
  itemFiltersStore.offeredSinceVariant =
    offeredSinceVariant || DEFAULT_OFFERED_SINCE_VARIANT;

  const tags = params[QueryParamName.TAGS];
  itemFiltersStore.tags = tags ? new Set(tags.split(',')) : new Set();

  const currencies = params[QueryParamName.CURRENCIES];
  itemFiltersStore.currencies = currencies
    ? new Set(currencies.split(','))
    : new Set();

  const locationSearch = params[QueryParamName.LOC_SEARCH];
  searchStore.locationSearchText = locationSearch || '';

  const search = params[QueryParamName.SEARCH];
  searchStore.companyItemSearchText = search || '';

  const isBookmarkedParam = params[QueryParamName.IS_BOOKMARKED];
  itemFiltersStore.isBookmarked = isBookmarkedParam === 'true';

  Logger.debug('Local and store variables updated from query params');
}

function updateQueryParams() {
  const page =
    currentPage.value !== DEFAULT_PAGE ? currentPage.value.toString() : null;
  const pageSize =
    currentPageSize.value !== DEFAULT_PAGE_SIZE
      ? currentPageSize.value.toString()
      : null;
  const sortBy =
    currentSortBy.value !== DEFAULT_SORT_BY
      ? currentSortBy.value.toString()
      : null;
  const includePhotos = !itemFiltersStore.includePhotos ? 'false' : null;

  const offeredSinceVariant =
    itemFiltersStore.offeredSinceVariant !==
    OfferedSinceOptionVariant.NO_PREFERENCE
      ? itemFiltersStore.offeredSinceVariant
      : null;
  const tags =
    itemFiltersStore.tags.size > 0
      ? Array.from(itemFiltersStore.tags).join(',')
      : null;
  const currencies =
    itemFiltersStore.currencies.size > 0
      ? Array.from(itemFiltersStore.currencies).join(',')
      : null;
  const isBookmarked = itemFiltersStore.isBookmarked === true ? 'true' : null;

  const params: QueryParams = {
    [QueryParamName.PAGE]: page,
    [QueryParamName.PAGE_SIZE]: pageSize,
    [QueryParamName.SORT_BY]: sortBy,
    [QueryParamName.INCLUDE_PHOTOS]: includePhotos,
    [QueryParamName.OFFERED_SINCE]: offeredSinceVariant as string | null,
    [QueryParamName.TAGS]: tags,
    [QueryParamName.CURRENCIES]: currencies,
    [QueryParamName.LOC_SEARCH]: searchStore.locationSearch,
    [QueryParamName.SEARCH]: searchStore.companyItemSearch,
    [QueryParamName.IS_BOOKMARKED]: isBookmarked,
  };

  updateMultipleQueryParams(params);
  Logger.debug(`Query params updated with local and store variables ${params}`);
}

function loadItems() {
  itemsStore.loadPageItems({
    page: currentPage.value,
    pageSize: currentPageSize.value,
    sortBy: currentSortBy.value,
  });

  window.scrollTo({ top: 0, behavior: 'smooth' });
  Logger.debug('Items loaded');
}

function handlePageChange(newPageNumber: number) {
  currentPage.value = newPageNumber;
  updateQueryParams();
}

function handleSortByChange(newOption: SortByOption) {
  Logger.debug(`New sort option selected: ${newOption}`);
  currentSortBy.value = newOption;
  currentPage.value = DEFAULT_PAGE;
  updateQueryParams();
}

function handlePageSizeChange(newPageSize: number) {
  Logger.debug(`Page size changed to: ${newPageSize}`);
  currentPageSize.value = newPageSize;
  currentPage.value = DEFAULT_PAGE;
  updateQueryParams();
}

async function handleFiltersChange() {
  Logger.debug('Filters changed!');
  currentPage.value = DEFAULT_PAGE;
  updateQueryParams();
  areFiltersApplied.value = true;
}

function handleSearchTextChange() {
  if (
    !searchStore.locationSearchChanged &&
    !searchStore.companyItemSearchChanged
  ) {
    return;
  }
  currentPage.value = DEFAULT_PAGE;
  updateQueryParams();
}
searchStore.$subscribe(handleSearchTextChange);

async function downloadItemsCSV() {
  const response: GetItemsCSVResponse = await getItemsCSV({
    page: currentPage.value,
    pageSize: currentPageSize.value,
    sortBy: currentSortBy.value,
    tags: itemFiltersStore.tags,
    offeredSince: itemFiltersStore.offeredSince,
    currencies: itemFiltersStore.currencies,
    includePhotos: itemFiltersStore.includePhotos,
    searchLocation: searchStore.locationSearch,
    search: searchStore.companyItemSearch,
    isBookmarked: itemFiltersStore.isBookmarked,
  });

  if (response.error !== null) {
    Logger.info(`[downloadCSV] Could not get CSV ${response.error}`);
    throw response.error;
  }

  if (response.data !== undefined) {
    const filenamePrefix = itemFiltersStore.isBookmarked
      ? CSV_FILENAME.MARKETPLACE_BOOKMARKED
      : CSV_FILENAME.MARKETPLACE;

    saveFile(response.data, makeCSVFilename(filenamePrefix));
  }
}

itemsStore.$onAction(({ name, after }) => {
  after(() => {
    Logger.debug(`[ItemListView][itemsStore][$onAction] ${name}`);
    if (name === 'loadPageItems') {
      Logger.debug(
        `[ItemListView][itemsStore][$onAction]
        AFTER
        ${name}
        -> initialize tooltips,
      `,
      );
      nextTick().then(() => {
        initTooltips();
      });
    }
  });
});

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    authStore.logout();
    return;
  }

  readQueryParams();

  tracking?.trackViewItemList();

  itemsStore.isLoading = true;
  itemsStore.clear();

  await nextTick();

  loadItems();

  initFlowbite();
});
</script>

<template>
  <main class="item-list-view overflow-hidden relative flex-1">
    <BaseFlexbox class="absolute h-full w-full" alignItems="stretch">
      <BaseFlexbox
        direction="column"
        class="transition-all overflow-hidden"
        alignItems="stretch"
        :class="{
          'w-80 max-w-80': itemsStore.filtersEnabled,
          'w-0': !itemsStore.filtersEnabled,
        }"
      >
        <div
          class="pt-10 px-5 mb-5"
          v-if="ITEM_LIST_FILTERS_FEATURE_FLAGS.TOGGLE_FILTERS_BUTTONS"
        >
          <FhButton
            variant="outlined"
            :disabled="!itemsStore.filtersEnabled"
            size="lg"
            @click="itemsStore.hideFilters"
            data-test-id="hide-filters-button"
          >
            Hide Filters
          </FhButton>
        </div>

        <div class="mx-5 font-medium">
          <span>Filters</span>
          <hr />
        </div>

        <div class="box-border flex-auto relative">
          <ItemListFilters
            class="box-border absolute px-5 w-full h-full overflow-y-scroll scrollbar scrollbar-thumb-gray-300 scrollbar-track-transparent"
            @filtersChanged="handleFiltersChange"
          />
        </div>
      </BaseFlexbox>

      <div class="px-5 py-5 mx-auto flex-1 border-l flex flex-col">
        <BaseFlexbox justifyContent="space-between" alignItems="center">
          <div v-if="ITEM_LIST_FILTERS_FEATURE_FLAGS.TOGGLE_FILTERS_BUTTONS">
            <FhButton
              variant="outlined"
              size="lg"
              v-show="!itemsStore.filtersEnabled"
              data-test-id="show-filters-button"
              @click="itemsStore.showFilters"
            >
              Show Filters
            </FhButton>
          </div>

          <BaseFlexbox justifyContent="end" alignItems="center">
            <FhSelect
              v-model="currentSortBy"
              :options="SORT_BY_OPTIONS"
              data-test-id="current-sort-by"
              @update:modelValue="handleSortByChange"
            ></FhSelect>
            <FhTooltip>
              <a
                @click.prevent="downloadItemsCSV"
                class="flex justify-center items-center ml-5 border border-gray-300 rounded-md hover:bg-gray-100 cursor-pointer p-2"
                data-test-id="download-csv-button"
              >
                <FhIcon :icon="download" size="lg" />
              </a>
              <template #content>
                <FhText>
                  You can change the page size in the bottom! (Default page
                  size: 30)
                </FhText>
              </template>
            </FhTooltip>
          </BaseFlexbox>
        </BaseFlexbox>

        <div
          class="table-container flex-1 overflow-y-scroll overflow-x-hidden mt-4"
        >
          <DeskTable
            class="item-list-table w-full font-medium"
            :column-definitions="itemListColumnConfig"
            :data="itemsTableData"
            :no-rows-message="'No items available!'"
            dataTestId="item-list-table"
            :isLoading="itemsStore.isLoading"
          />
        </div>
        <ItemListFooter
          class="mt-3 w-full"
          :showPagination="!itemsStore.isLoading && itemsTableData.length > 0"
          :showCount="!itemsStore.isLoading && itemsTableData.length > 0"
          :pageStart="pageStart"
          :pageEnd="pageEnd"
          :totalItemsCount="itemsStore.totalCount"
          :totalPagesCount="totalPagesCount"
          :currentPage="currentPage"
          :currentPageSize="currentPageSize"
          :pageSizeOptions="PAGE_SIZE_OPTIONS"
          @pageChanged="handlePageChange"
          @pageSizeChanged="handlePageSizeChange"
          dataTestId="footer-section"
        />
      </div>
    </BaseFlexbox>
  </main>
</template>

<style lang="scss" scoped>
.item-list-view {
  .table-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  :deep(.item-list-table) {
    thead {
      border-top: none;
      border-left: none;
      border-right: none;

      tr {
        th {
          @apply font-semibold;
          position: sticky; /* Keep table header sticky at the top */
          top: 0;
          z-index: 1;
          background-color: white;
        }
      }
    }

    tbody {
      tr {
        border-left: none;
        border-right: none;

        td {
          border-left: none;
          border-right: none;
          @apply font-normal;
        }
      }

      @apply text-base;
    }

    > div {
      > div {
        > div {
          @apply rounded-t-lg;
        }
      }
    }
  }
}
</style>
