import { useToastStore } from '@/stores/useToastStore';

export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
} as const;

export type HttpMethod = (typeof HTTP_METHOD)[keyof typeof HTTP_METHOD];

export async function apiRequest<T>({
  url,
  method,
  body,
}: {
  url: string;
  method: HttpMethod;
  body: object;
}): Promise<T> {
  const toastStore = useToastStore();

  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const data = await response.json();
      const errorMessage = data?.message || 'An error occurred.';
      toastStore.error(errorMessage);
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'Something went wrong.';
    toastStore.error(errorMessage);
    throw new Error(errorMessage);
  }
}
