/* eslint-disable class-methods-use-this */
import mixpanel from 'mixpanel-browser';
import { Logger } from '@/common/Logger';
import {
  type CopyAsnLinkEventProperties,
  type LoginTrackingEventProperties,
  type TrackingService,
  type PreviewButtonClickEventProperties,
} from './TrackingService';

/** Represents the names of the events that can be tracked. */
enum MixpanelEvent {
  ASN_LINK_COPY = 'asn-link-copy',
  VIEW_ITEM_LIST = 'view-item-list',
  LOGIN = 'login',
  CLICK_PREVIEW_BUTTON = 'click-preview-button',
}

/**
 * A Mixpanel-based implementation of the `TrackingService` interface.
 *
 * This class integrates with the Mixpanel analytics service to track
 * user actions within the application.
 */
export class MixpanelTrackingService implements TrackingService {
  /**
   * Constructs the MixpanelTrackingService and initializes Mixpanel.
   *
   * @param token - The Mixpanel project token.
   * @param debug - Flag to enable Mixpanel debug mode.
   */
  constructor(token: string, debug: boolean) {
    if (!token) {
      Logger.error('Mixpanel token is missing. Initialization aborted.');
      throw new Error('Mixpanel token is missing.');
    }

    try {
      mixpanel.init(token, {
        debug,
        track_pageview: false,
        persistence: 'localStorage',
        record_sessions_percent: 50,
      });
      Logger.debug('Mixpanel initialized successfully.');
    } catch (error) {
      Logger.error(`Failed to initialize Mixpanel: ${error}`);
    }
  }

  public setSuperProperties(properties: Record<string, any>): void {
    try {
      mixpanel.register(properties);
      Logger.debug('Super properties set successfully:', properties);
    } catch (error) {
      Logger.error(`Failed to set super properties: ${error}`);
    }
  }

  /**
   * Tracks a login event with Mixpanel.
   *
   * @param event - The login tracking event containing user information.
   */
  public trackLogin(eventProperties: LoginTrackingEventProperties): void {
    // When user logs in, set their affiliate shortname as a super property
    if (eventProperties.affiliateShortname) {
      this.setSuperProperties({
        'affiliate-short-name': eventProperties.affiliateShortname,
      });
    }

    mixpanel.track(MixpanelEvent.LOGIN, {
      'is-admin': eventProperties.isAdmin,
    });
  }

  /**
   * Tracks a view of the item list.
   */
  public trackViewItemList(): void {
    mixpanel.track(MixpanelEvent.VIEW_ITEM_LIST);
  }

  /**
   * Tracks a click on the "Copy Link" button for an ASN.
   */
  public trackCopyAsnLink(eventProperties: CopyAsnLinkEventProperties): void {
    const payload = {
      'item-id': eventProperties.itemId,
      'item-name': eventProperties.itemName,
      'provider-shortname': eventProperties.companyShortname,
      'referral-link': eventProperties.referralLink,
    };

    mixpanel.track(MixpanelEvent.ASN_LINK_COPY, payload);
  }

  public trackPreviewButtonClick(
    eventProperties: PreviewButtonClickEventProperties,
  ): void {
    const payload = {
      'item-id': eventProperties.itemId,
      'item-name': eventProperties.itemName,
      'provider-shortname': eventProperties.companyShortname,
      'referral-link': eventProperties.referralLink,
    };
    mixpanel.track(MixpanelEvent.CLICK_PREVIEW_BUTTON, payload);
  }
}
