import { UTCDate } from '@date-fns/utc';
import { authorizationHeaders } from '@/common/authorizationHeaders';
import type { SortByOption } from '@/common/constants/items';
import { NO_CONTENT } from '@/common/constants/status';
import { authorizedFetch } from './authorizedFetch';
import { type GenericResponse } from './base';

export const MAX_PAGE_SIZE: number = 100; // as defined in the backend pagination handler

export interface GetItemsURLOptions {
  page?: number | null;
  pageSize?: number | null;
  sortBy?: SortByOption | null;
  tags?: Set<string> | null;
  offeredSince?: UTCDate | null;
  currencies?: Set<string> | null;
  includePhotos?: boolean | null;
  searchLocation?: string | null;
  search?: string | null;
  csv?: boolean | null;
  isBookmarked?: boolean | null;
}

export interface GetItemsOptions {
  page?: number | null;
  pageSize?: number | null;
  sortBy?: SortByOption | null;
  tags?: Set<string> | null;
  offeredSince?: UTCDate | null;
  currencies?: Set<string> | null;
  includePhotos?: boolean | null;
  searchLocation?: string | null;
  search?: string | null;
  isBookmarked?: boolean | null;
}

type FetchDataResponse = GenericResponse<any>;

/*
 * Generic function to fetch data as an authenticated user from the given URL.
 * It returns a GenericResponse object with the status, data, and error.
 * Redirection logic has been removed and should be handled in the router guards.
 */
export const fetchData = async (url: URL): Promise<FetchDataResponse> => {
  const response: Response = await authorizedFetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
  });

  let error: Error | null = null;
  if (!response.ok) {
    error = new Error(`HTTP error! status: ${response.status}`);
    // Removed redirection logic here
  }

  const data =
    response.status !== NO_CONTENT ? await response.json() : undefined;

  const fetchDataResponse: FetchDataResponse = {
    status: response.status,
    data,
    error,
  };

  return fetchDataResponse;
};
