<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { FhButton, FhSpinner, FhIcon } from '@fareharbor-com/beacon-vue';
import { bookmark, bookmarkFilled } from '@fareharbor-com/beacon-vue/icons';
import { initFlowbite, initTooltips } from 'flowbite';
import { Logger } from '@/common/Logger';
import { addItemToBookmarks } from '@/common/api/addItemToBookmarks';
import { deleteItemFromBookmarks } from '@/common/api/deleteItemFromBookmarks';
import { getItem } from '@/common/api/getItem';
import { getItemAvailabilityCount } from '@/common/api/getItemAvailabilityCount';
import { getItemImages } from '@/common/api/getItemImages';
import { NOT_FOUND } from '@/common/constants/status';
import { handleThrownError } from '@/common/errors/handleThrownError';
import { markdownToHtml } from '@/common/markdownToHtml';
import ItemNotFound from '@/components/itemDetail/ItemNotFound.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useDebounce } from '@/composables/useDebounce';
import { transformSingleItemData } from '@/stores/items';
import { useToastStore } from '@/stores/useToastStore';
import { useUserStore } from '@/stores/user';
import type { Item } from '@/types';

const ImageGallery = defineAsyncComponent(
  () => import('@/components/itemDetail/ImageGallery.vue'),
);
const ItemSupplier = defineAsyncComponent(
  () => import('@/components/itemDetail/ItemSupplier.vue'),
);
const StructuredDescription = defineAsyncComponent(
  () => import('@/components/itemDetail/StructuredDescription.vue'),
);

const route = useRoute();
const userStore = useUserStore();
const toastStore = useToastStore();

const isItemLoading = ref(true);
const isImagesLoading = ref(true);
const errorLoadingItem = ref(false);
const notFoundItem = ref(false);
const isAddingBookmarkLoading = ref(false);
const isBookmarked = ref(false);

const bookmarkError = ref<Error | null>(null);

const currentItem = ref<Item | null>(null);
const currentItemImages = ref<string[]>([]);

const availabilityCount = ref<number | null>(null);
const isAvailabilityCountLoading = ref(false);

// With these computed properties:
const company = computed(() => currentItem.value?.company);
const user = computed(() => userStore.user);

const isLoading = computed(
  () =>
    isItemLoading.value ||
    isImagesLoading.value ||
    isAvailabilityCountLoading.value,
);

const fetchItemDetail = async (itemPk: number) => {
  try {
    const { data, status, error } = await getItem(itemPk);
    if (error) {
      if (status === NOT_FOUND) {
        notFoundItem.value = true;
        errorLoadingItem.value = false;
        return;
      }
      Logger.info(`Unable to fetch item: ${error.message}`);
      errorLoadingItem.value = true;
      return;
    }
    const transformedItem = await transformSingleItemData(data);
    currentItem.value = transformedItem;
    isBookmarked.value = !!transformedItem.isBookmarked;

    await nextTick();
    initTooltips();
  } catch (error) {
    handleThrownError({
      error,
      toastMessage: 'Unexpected error occurred while fetching item.',
      log: true,
    });
    errorLoadingItem.value = true;
  } finally {
    isItemLoading.value = false;
  }
};

const fetchItemImages = async (itemPk: number) => {
  isImagesLoading.value = true;
  try {
    const { data: imageData = [], error: imageError } =
      await getItemImages(itemPk);
    if (imageError) {
      Logger.info(`Unable to fetch item images: ${imageError.message}`);
      return;
    }
    currentItemImages.value = imageData.map((image) => image.image_url);
  } catch (error) {
    handleThrownError({
      error,
      toastMessage: 'Unexpected error occurred while fetching item images.',
      log: true,
    });
  } finally {
    isImagesLoading.value = false;
  }
};

async function fetchAvailabilityCount(itemPk: number) {
  isAvailabilityCountLoading.value = true;
  try {
    const { data } = await getItemAvailabilityCount(itemPk);
    availabilityCount.value = data?.count ?? null;
  } catch (error) {
    availabilityCount.value = null;
  } finally {
    isAvailabilityCountLoading.value = false;
  }
}

const handleAddItemToBookmark = async () => {
  if (!currentItem.value) return;

  isAddingBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    const response = await addItemToBookmarks(currentItem.value.itemId);

    if (response.error) {
      bookmarkError.value = response.error;
      toastStore.error('Error adding bookmark, please try again.');
      Logger.info('Error adding bookmark:', response.error);
    } else {
      toastStore.success('Bookmark added successfully.');
      isBookmarked.value = true;
    }
  } catch (error) {
    handleThrownError({
      error,
      toastMessage: 'An unexpected error occurred while adding bookmark.',
      log: true,
    });
    bookmarkError.value = error as Error;
  } finally {
    isAddingBookmarkLoading.value = false;
  }
};

const handleRemoveItemFromBookmark = async () => {
  if (!currentItem.value) return;

  isAddingBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    const response = await deleteItemFromBookmarks(currentItem.value.itemId);

    if (response.error) {
      bookmarkError.value = response.error;
      toastStore.error('Error removing bookmark, please try again.');
      Logger.info('Error removing bookmark:', response.error);
    } else {
      toastStore.success('Bookmark removed successfully.');
      isBookmarked.value = false;
    }
  } catch (error) {
    handleThrownError({
      error,
      toastMessage: 'An unexpected error ,occurred while removing bookmark',
      log: true,
    });
    bookmarkError.value = error as Error;
  } finally {
    isAddingBookmarkLoading.value = false;
  }
};

const loadItem = async () => {
  const { itemPk } = route.params;
  if (itemPk) {
    const itemPkNumber = Number(itemPk);

    isItemLoading.value = true;
    isImagesLoading.value = false;
    isAvailabilityCountLoading.value = false;
    errorLoadingItem.value = false;
    notFoundItem.value = false;
    currentItem.value = null;
    currentItemImages.value = [];
    isBookmarked.value = false;

    await fetchItemDetail(itemPkNumber);

    if (!notFoundItem.value && !errorLoadingItem.value) {
      await Promise.all([
        fetchItemImages(itemPkNumber),
        fetchAvailabilityCount(itemPkNumber),
      ]);
    }
  }
};

const getTransformedDescription = (item: Item): string | null =>
  item.descriptionSafeHtml
    ? item.descriptionSafeHtml
    : markdownToHtml(item.description || '');

const debouncedLoadItem = useDebounce(loadItem, 300);

onMounted(async () => {
  await userStore.fetchUserData();
  await loadItem();
  initFlowbite();
});

watch(
  () => route.params.itemPk,
  (newItemPk) => {
    if (newItemPk) {
      debouncedLoadItem(newItemPk);
    }
  },
);
</script>

<template>
  <main class="container mx-auto px-4 pt-7 pb-36 flex-1 content-center">
    <!-- LOADING SPINNER -->
    <template v-if="isLoading">
      <div class="flex items-center justify-center">
        <FhSpinner size="lg" />
      </div>
    </template>

    <!-- ITEM DETAILS -->
    <template v-else-if="currentItem && !isLoading">
      <BaseFlexbox
        class="min-h-screen"
        direction="column"
        data-test-id="item-details-page-section"
      >
        <!-- Tags -->
        <span class="text-xs font-normal text-gray-500">
          {{ currentItem.tags.map((tag) => tag.name.toUpperCase()).join(', ') }}
        </span>

        <!-- Name -->
        <span class="text-3xl font-medium mt-1" data-test-id="item-name-text">
          {{ currentItem.name }}
        </span>

        <!-- Headline -->
        <span class="text-lg font-medium text-gray-400">
          {{ currentItem.headline }}
        </span>

        <!-- 3/5 - 2/5 Grid Layout on large screens -->
        <div class="grid grid-cols-1 lg:grid-cols-5 gap-12 mt-6">
          <!-- LEFT: 3 of 5 columns -->
          <div class="lg:col-span-3">
            <BaseFlexbox direction="column" grow="1" gap="4">
              <!-- Image Gallery (lazy‐loaded) -->
              <Suspense>
                <template #default>
                  <ImageGallery
                    :images="currentItemImages"
                    :isLoading="isImagesLoading"
                  />
                </template>
                <template #fallback>
                  <div class="flex items-center justify-center h-32">
                    <FhSpinner size="sm" />
                  </div>
                </template>
              </Suspense>

              <!-- Bookmark Buttons -->
              <BaseFlexbox
                justify-content="end"
                class="pb-3 border-b border-gray-300"
              >
                <template v-if="!isBookmarked">
                  <FhButton
                    size="sm"
                    @click="handleAddItemToBookmark"
                    :disabled="isAddingBookmarkLoading"
                    data-test-id="item-bookmark-unsaved-btn"
                  >
                    <template v-if="isAddingBookmarkLoading">
                      <FhSpinner size="md" />
                      Bookmarking Item
                    </template>
                    <template v-else>
                      <FhIcon :icon="bookmark" />
                      Bookmark item
                    </template>
                  </FhButton>
                </template>

                <template v-else>
                  <FhButton
                    size="sm"
                    @click="handleRemoveItemFromBookmark"
                    :disabled="isAddingBookmarkLoading"
                    data-test-id="item-bookmark-saved-btn"
                  >
                    <template v-if="isAddingBookmarkLoading">
                      <FhSpinner size="md" />
                      Bookmarking
                    </template>
                    <template v-else>
                      <FhIcon :icon="bookmarkFilled" />
                      Bookmarked
                    </template>
                  </FhButton>
                </template>
              </BaseFlexbox>

              <!-- Description or structured description -->
              <template v-if="currentItem.structuredDescription">
                <StructuredDescription
                  :itemDescription="currentItem.structuredDescription"
                  :item="currentItem"
                />
              </template>
              <template v-else>
                <BaseFlexbox class="mt-3 mb-6" direction="column">
                  <div
                    class="prose"
                    data-test-id="item-details-description-section"
                    v-html="getTransformedDescription(currentItem)"
                  />
                </BaseFlexbox>
              </template>
            </BaseFlexbox>
          </div>

          <!-- RIGHT: 2 of 5 columns -->
          <div class="lg:col-span-2">
            <BaseFlexbox direction="column" grow="1">
              <Suspense>
                <template #default>
                  <ItemSupplier
                    :name="currentItem.name"
                    :itemId="currentItem.itemId"
                    :companyId="company?.id?.toString() ?? ''"
                    :companyUrl="company?.companyUrl ?? null"
                    :location="company?.primaryLocation"
                    :supplierItemCount="currentItem.companyItemCount"
                    :companyImage="company?.companyImage ?? null"
                    :inFhdnApi="user?.inFhdnApi"
                    :inFhdn="user?.inFhdn"
                    :isStaff="user?.user?.isStaff"
                    :companyCurrency="company?.currency ?? ''"
                    :companyShortname="company?.shortName ?? ''"
                    :affiliateShortname="user?.company?.shortName"
                    :referralLinks="currentItem.referralLinks"
                    :availabilityCount="availabilityCount"
                  />
                </template>
                <template #fallback>
                  <div class="flex items-center justify-center h-32">
                    <FhSpinner size="sm" />
                  </div>
                </template>
              </Suspense>
            </BaseFlexbox>
          </div>
        </div>
      </BaseFlexbox>
    </template>

    <!-- ITEM NOT FOUND -->
    <template v-else-if="notFoundItem">
      <ItemNotFound />
    </template>
  </main>
</template>
