import { defineStore } from 'pinia';
import { Logger } from '@/common/Logger';
import { getLocations } from '@/common/api/getLocations';
import { sessionCache } from '@/common/cacheService';
import { capitalize } from '@/common/capitalize';
import { handleThrownError } from '@/common/errors/handleThrownError';
import type {
  LocationListPayload,
  LocationPayload,
  LocationSuggestion,
  MatchedFieldType,
} from '@/types';

const SESSION_STORAGE_KEY = 'locations_cache';

interface Location {
  city: string;
  state: string;
  country: string;
}

interface LocationsState {
  locations: Location[];
  isLoading: boolean;
}

export function unifyLocations(locations: Location[]): Location[] {
  const uniqueLocations: Location[] = [];
  const locationSet = new Set<string>();

  locations.forEach((location) => {
    const city = (location.city || '').toLowerCase().trim();
    const state = (location.state || '').toLowerCase().trim();
    const country = (location.country || '').toLowerCase().trim();

    const cityStateCountryLocationKey = `${city}-${state}-${country}`;
    if (!locationSet.has(cityStateCountryLocationKey)) {
      locationSet.add(cityStateCountryLocationKey);
      uniqueLocations.push(location);
    }

    const stateCountryLocationKey = `-${state}-${country}`;
    if (!locationSet.has(stateCountryLocationKey)) {
      locationSet.add(stateCountryLocationKey);
      const stateCountryLocation: Location = {
        city: '',
        state: location.state,
        country: location.country,
      };
      uniqueLocations.push(stateCountryLocation);
    }

    const countryLocationKey = `--${country}`;
    if (!locationSet.has(countryLocationKey)) {
      locationSet.add(countryLocationKey);
      const countryLocation: Location = {
        city: '',
        state: '',
        country: location.country,
      };
      uniqueLocations.push(countryLocation);
    }
  });

  return uniqueLocations;
}

async function transformSingleLocationData(
  payload: LocationPayload,
): Promise<Location> {
  return {
    city: payload.city,
    state: payload.state,
    country: payload.country,
  };
}

const transformLocationsData = async (
  data: LocationListPayload,
): Promise<Array<Location>> =>
  Promise.all(
    data.map(async (payload) => transformSingleLocationData(payload)),
  );

export const useLocationStore = defineStore('locations', {
  state: (): LocationsState => ({
    locations: [],
    isLoading: false,
  }),
  actions: {
    async fetchLocations() {
      this.isLoading = true;

      const cachedLocations =
        sessionCache.getItem<Location[]>(SESSION_STORAGE_KEY);
      if (cachedLocations && cachedLocations.length > 0) {
        this.locations = cachedLocations;
        this.isLoading = false;
        return;
      }

      try {
        const { status, data } = await getLocations();
        if (status !== 200 || !data) {
          throw new Error('Failed to load locations');
        }
        const locations = await transformLocationsData(data);
        this.locations = unifyLocations(locations);
        Logger.debug(
          `[stores][locations] Loaded ${this.locations.length} locations`,
        );

        sessionCache.setItem(SESSION_STORAGE_KEY, this.locations);
      } catch (error) {
        handleThrownError({
          error,
          toastMessage: 'Something went wrong while loading locations.',
          log: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    getSuggestions(prefix: string | null): LocationSuggestion[] {
      if (!prefix) return [];

      const normalize = (str: string) =>
        str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

      const prefixWords = normalize(prefix)
        .toLowerCase()
        .split(',')
        .map((word) => word.trim())
        .filter((word) => word.length > 0);

      if (prefixWords.length === 0) {
        return [];
      }

      const matchesPrefix = (
        location: Location,
      ): { matches: boolean; matchedFields: MatchedFieldType[] } => {
        const normalizedLocation = {
          country: normalize(location.country),
          state: normalize(location.state || ''),
          city: normalize(location.city || ''),
        };

        // Track matched fields
        const matchedFields: MatchedFieldType[] = [];

        prefixWords.forEach((prefixWord) => {
          if (
            !matchedFields.includes('country') &&
            normalizedLocation.country.includes(prefixWord)
          ) {
            matchedFields.push('country');
          } else if (
            !matchedFields.includes('state') &&
            normalizedLocation.state.includes(prefixWord)
          ) {
            matchedFields.push('state');
          } else if (
            !matchedFields.includes('city') &&
            normalizedLocation.city.includes(prefixWord)
          ) {
            matchedFields.push('city');
          }
        });

        return {
          matches: matchedFields.length === prefixWords.length,
          matchedFields,
        };
      };

      const matchingLocations = this.locations
        .map((location) => {
          const { matches, matchedFields } = matchesPrefix(location);
          if (!matches) return null;

          // Determine locationType for sorting
          let locationType = 4; // default to 4 (city, state, country)
          const hasCountry = !!location.country;
          const hasState = !!location.state;
          const hasCity = !!location.city;

          if (hasCountry && !hasState && !hasCity) {
            locationType = 1; // country only
          } else if (hasCountry && hasState && !hasCity) {
            locationType = 2; // state and country
          } else if (hasCountry && hasCity && !hasState) {
            locationType = 3; // city and country
          } else if (hasCountry && hasState && hasCity) {
            locationType = 4; // city, state, country
          }

          return {
            country: capitalize(location.country),
            state: capitalize(location.state),
            city: capitalize(location.city),
            matchedFields,
            locationType,
          };
        })
        .filter(
          (location): location is LocationSuggestion => location !== null,
        );

      // Sort locations based on locationType and then alphabetically
      const sortedLocations = matchingLocations.sort((a, b) => {
        // First sort by locationType
        if (a.locationType !== b.locationType) {
          return a.locationType - b.locationType;
        }

        const locationA = `${normalize(a.country)}, ${normalize(a.state)}, ${normalize(a.city)}`;
        const locationB = `${normalize(b.country)}, ${normalize(b.state)}, ${normalize(b.city)}`;
        return locationA.localeCompare(locationB);
      });

      return sortedLocations.slice(0, 5);
    },
  },
});
