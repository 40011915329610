import {
  type RouteRecordRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  guardBadPageQueryParam,
  guardEmptyQueryParams,
  guardUnauthenticated,
} from '@/router/guards';
import { useAuthStore } from '@/stores/useAuthStore';
import AcceptInvitationView from '@/views/AcceptInvitationView.vue';
import ConfirmPasswordResetView from '@/views/ConfirmPasswordResetView.vue';
import ItemDetailsView from '@/views/ItemDetailsView.vue';
import ItemListView from '@/views/ItemListView.vue';
import LoginView from '@/views/LoginView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import RequestPasswordResetView from '@/views/RequestPasswordResetView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: RequestPasswordResetView,
  },
  {
    path: '/confirm-password-reset',
    name: 'confirm-password-reset',
    component: ConfirmPasswordResetView,
  },
  {
    path: '/accept-invitation',
    name: 'accept-invitation',
    component: AcceptInvitationView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: () => {
      const authStore = useAuthStore();
      if (authStore.isAuthenticated) {
        return { name: 'item-list' };
      }
      return true;
    },
  },
  {
    path: '/',
    name: 'item-list',
    component: ItemListView,
    beforeEnter: [
      guardUnauthenticated,
      guardBadPageQueryParam,
      guardEmptyQueryParams,
    ],
  },
  {
    path: '/items/:itemPk',
    name: 'item-detail',
    props: true,
    component: ItemDetailsView,
    beforeEnter: guardUnauthenticated,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
    beforeEnter: guardUnauthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
