import { formatISO } from 'date-fns';

/**
 * Generates a CSV filename with the given prefix and the current date.
 * Format: `${prefix}-YYYY-MM-DD.csv`
 *
 * @param prefix - The prefix for the filename.
 * @returns The generated CSV filename.
 */
export function makeCSVFilename(prefix: string): string {
  const now = new Date();
  const formattedDate = formatISO(now, { representation: 'date' });
  return `${prefix}-${formattedDate}.csv`;
}
