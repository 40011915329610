import { API_URLS } from '@/configs';
import type { LocationListPayload } from '@/types';
import { fetchData } from '.';
import type { GenericResponse } from './base';

export type GetLocationsResponse = GenericResponse<LocationListPayload>;

export const getLocations = async (): Promise<GetLocationsResponse> => {
  const url = new URL(`${API_URLS.BASE}/locations/`);
  const getLocationsResponse = await fetchData(url);
  return getLocationsResponse;
};
