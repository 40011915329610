import { authorizationHeaders } from '@/common/authorizationHeaders';
import { FORBIDDEN, UNAUTHORIZED } from '@/common/constants/status';
import { useAuthStore } from '@/stores/useAuthStore';

/**
 * This function is used to fetch data from the server.
 * It will automatically refresh the access token if it is expired.
 * @param args - The arguments to pass to the fetch function.
 * @returns The response from the fetch function.
 */
export async function authorizedFetch(
  ...args: Parameters<typeof fetch>
): Promise<Response> {
  const response = await fetch(...args);
  if (response.status === UNAUTHORIZED || response.status === FORBIDDEN) {
    const authStore = useAuthStore();
    const isRefreshed = await authStore.doTokenRefresh();
    if (isRefreshed) {
      const newerArgs: typeof args = [...args];
      const oldOptions = newerArgs[1] || {};
      const oldHeaders = oldOptions.headers || {};
      const newHeaders = {
        ...oldHeaders,
        ...authorizationHeaders(),
      };
      const newOptions = {
        ...oldOptions,
        headers: newHeaders,
      };
      newerArgs[1] = newOptions;
      return fetch(...newerArgs);
    }
  }
  return response;
}
