<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue';
import type { LocationSuggestion, MatchedFieldType } from '@/types';

interface LocationSearchSuggestionProps {
  suggestion: LocationSuggestion;
  prefix: string;
}

const props = defineProps<LocationSearchSuggestionProps>();
const emit = defineEmits(['click']);

// Computed property to check if no location is found
const noLocationsFound = computed(() => {
  const { city, state, country } = props.suggestion;
  return city === '' && state === '' && country === '';
});

// Handle click, only emit if the suggestion is valid
const handleClick = () => {
  if (!noLocationsFound.value) {
    emit('click', props.suggestion); // Emit the entire suggestion object
  }
};

// Compute highlighted text based on the matched fields
const highlightedText = computed(() => {
  if (noLocationsFound.value) return '';
  const fields: MatchedFieldType[] = ['city', 'state', 'country'];
  const parts: string[] = [];
  const prefixLower = props.prefix.toLowerCase();

  fields.forEach((field) => {
    const value = props.suggestion[field];
    if (value) {
      const valueLower = value.toLowerCase();
      const index = valueLower.indexOf(prefixLower);
      if (index !== -1) {
        const before = value.slice(0, index);
        const match = value.slice(index, index + props.prefix.length);
        const after = value.slice(index + props.prefix.length);
        parts.push(`${before}<span>${match}</span>${after}`);
      } else {
        parts.push(value);
      }
    }
  });
  return parts.join(', ');
});
</script>

<template>
  <div
    class="p-2 cursor-pointer hover:bg-gray-100"
    data-test-id="location-search-suggestion"
    @click="handleClick"
  >
    <!-- No Locations Found -->
    <div v-if="noLocationsFound" class="p-2 text-center text-gray-500">
      <p>No locations found!</p>
    </div>

    <!-- Display formatted suggestion -->
    <div v-else>
      <span v-html="highlightedText"></span>
    </div>
  </div>
</template>
