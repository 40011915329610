import { defineStore } from 'pinia';
import { Logger } from '@/common/Logger';
import type { GenericResponse } from '@/common/api/base';
import { authTokenService } from '@/common/authTokenService';
import { FORBIDDEN, OK, UNAUTHORIZED } from '@/common/constants/status';
import { handleThrownError } from '@/common/errors/handleThrownError';
import { useToastStore } from '@/stores/useToastStore';
import type { LoginOptions, TokenPayload } from '@/types';

export interface AuthStoreState {
  hasTokens: boolean;
  loginOptions: LoginOptions;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreState => ({
    hasTokens: authTokenService.hasTokens(),
    loginOptions: {},
  }),

  getters: {
    isAuthenticated: (state): boolean => state.hasTokens,
  },

  actions: {
    async obtainTokens(
      username: string,
      password: string,
    ): Promise<GenericResponse<TokenPayload> | null> {
      const toastStore = useToastStore();
      try {
        const response = await authTokenService.obtainTokens(
          username,
          password,
        );
        if (response.status === OK && response.data) {
          this.hasTokens = true;
          toastStore.success('Login complete—enjoy your session!');
          return response;
        }
        if (response.status === UNAUTHORIZED || response.status === FORBIDDEN) {
          this.logout();
        }
        Logger.info('Unexpected error during token obtainment', {
          status: response.status,
        });

        return response;
      } catch (error) {
        handleThrownError({
          error,
          toastMessage: 'Unexpected error occurred during login.',
          log: true,
        });
        return null;
      }
    },

    async doTokenRefresh(): Promise<boolean> {
      Logger.debug('Attempting token refresh ...');
      try {
        const isRefreshed = await authTokenService.refreshAccessToken();
        if (isRefreshed) {
          this.hasTokens = true;
          Logger.debug('Token refreshed successfully!');
        } else {
          Logger.info('Could not refresh token!');
          this.logout();
        }
        return isRefreshed;
      } catch (error) {
        handleThrownError({
          error,
          log: true,
          toastMessage: 'Something went wrong with token refresh.',
        });
      }
      return false;
    },

    setLoginOptions(options?: LoginOptions) {
      this.loginOptions = options || {};
    },

    logout() {
      authTokenService.clearTokens();
      localStorage.clear();
      this.hasTokens = false;
    },
  },
});
