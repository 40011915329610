<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { FhLink } from '@fareharbor-com/beacon-vue';
import LoginForm from '@/components/LoginForm/LoginForm.vue';
import { config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const currentEnvironment = config.ENVIRONMENT;
const isStagingOrLocal = computed(() =>
  [InventoryEnvironment.STAGING, InventoryEnvironment.LOCAL].includes(
    currentEnvironment,
  ),
);

const router = useRouter();
const authStore = useAuthStore();

onMounted(() => {
  if (!authStore.isAuthenticated) {
    router.push({ name: 'login' });
  }
});

const topBlurClass = computed(() =>
  isStagingOrLocal.value ? 'top-10' : 'top-0',
);
const loginFormHeight = computed(() =>
  isStagingOrLocal.value ? 'calc(100vh - 165px)' : 'calc(100vh - 150px)',
);
</script>

<template>
  <div class="flex flex-col" :style="{ height: loginFormHeight }">
    <div
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      data-test-id="login-modal"
      :class="[
        'fixed inset-x-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm',
        topBlurClass,
      ]"
    >
      <div
        class="relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg border border-gray-300"
      >
        <div
          class="flex flex-col items-center justify-center p-4 border-b border-gray-200 rounded-t space-y-2"
        >
          <img
            src="@/assets/images/logo.png"
            alt="Marketplace Logo"
            class="h-10"
          />
          <h3
            id="modal-title"
            class="text-xl font-semibold text-gray-900 text-left"
          >
            Log in to FareHarbor Marketplace
          </h3>
        </div>

        <div class="p-4">
          <LoginForm />
          <div class="text-sm font-medium text-gray-500 mt-4">
            <div class="flex flex-col items-start space-y-1">
              <p>
                Forgot your password?
                <FhLink
                  variant="primary"
                  @click.prevent="
                    router.push({ name: 'request-password-reset' })
                  "
                  data-test-id="request-password-reset-link"
                  href="#"
                >
                  Request password reset
                </FhLink>
              </p>
              <p>
                Not registered?
                <FhLink
                  variant="primary"
                  data-test-id="contact-support-link"
                  href="https://help.fareharbor.com/submit"
                >
                  Contact support
                </FhLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
