import { OfferedSinceOptionVariant } from '@/components/itemList/types';

export enum SortByOption {
  QUALITY_DESCENDING = 1,
  QUALITY_ASCENDING,
  COMPANY_SHORTNAME_DESCENDING,
  COMPANY_SHORTNAME_ASCENDING,
  ITEM_NAME_DESCENDING,
  ITEM_NAME_ASCENDING,
  OLDEST,
  NEWEST,
}

export const SORT_BY_OPTIONS = [
  {
    value: SortByOption.QUALITY_DESCENDING,
    label: 'Quality (High to Low)',
  },
  {
    value: SortByOption.QUALITY_ASCENDING,
    label: 'Quality (Low to High)',
  },
  {
    value: SortByOption.COMPANY_SHORTNAME_DESCENDING,
    label: 'Company name (Z-A)',
  },
  {
    value: SortByOption.COMPANY_SHORTNAME_ASCENDING,
    label: 'Company name (A-Z)',
  },
  {
    value: SortByOption.ITEM_NAME_DESCENDING,
    label: 'Item name (Z-A)',
  },
  {
    value: SortByOption.ITEM_NAME_ASCENDING,
    label: 'Item name (A-Z)',
  },
  {
    value: SortByOption.OLDEST,
    label: 'Oldest',
  },
  {
    value: SortByOption.NEWEST,
    label: 'Newest',
  },
];

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_SORT_BY = SortByOption.QUALITY_DESCENDING;
export const DEFAULT_OFFERED_SINCE_VARIANT =
  OfferedSinceOptionVariant.NO_PREFERENCE;

export enum QueryParamName {
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  SEARCH = 'search',
  LOC_SEARCH = 'locSearch',
  SORT_BY = 'sortBy',
  TAGS = 'tags',
  OFFERED_SINCE = 'offeredSince',
  CURRENCIES = 'currencies',
  INCLUDE_PHOTOS = 'includePhotos',
  IS_BOOKMARKED = 'isBookmarked',
}

export const PAGE_SIZE_OPTIONS = [
  { value: 30, label: '30', disabled: false },
  { value: 50, label: '50', disabled: false },
  { value: 100, label: '100', disabled: false },
];
