<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { FhButton, FhLink } from '@fareharbor-com/beacon-vue';
import { apiRequest } from '@/common/apiRequest';
import { API_URLS, config } from '@/configs';
import { HTTP_METHOD } from '@/types/HttpMethods';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const currentEnvironment = config.ENVIRONMENT;
const isStagingOrLocal = computed(() =>
  [InventoryEnvironment.STAGING, InventoryEnvironment.LOCAL].includes(
    currentEnvironment,
  ),
);

const router = useRouter();
const route = useRoute();

// Extract Email & Token from URL
const email = ref<string | null>(null);
const token = ref<string | null>(null);
const isSubmitting = ref(false);
const successMessage = ref('');
const errorMessage = ref('');
const isUserActive = ref(false);

onMounted(() => {
  email.value = (route.query.email as string) || null;
  token.value = (route.query.token as string) || null;
});

// Function to accept invitation
async function acceptInvitation() {
  isSubmitting.value = true;
  successMessage.value = '';
  errorMessage.value = '';
  isUserActive.value = false;

  try {
    const url = `${API_URLS.BASE}/accept-invitation/`;
    const response = (await apiRequest({
      url,
      method: HTTP_METHOD.POST,
      body: {
        email: email.value,
        token: token.value,
      },
    })) as { message: string };

    if (response.message === 'User is already activated.') {
      successMessage.value = 'User is already activated.';
      isUserActive.value = true;
    } else {
      successMessage.value =
        'Thank you for accepting our invitation. You will receive an email to set your password. You can close this tab.';
    }
  } catch (error) {
    errorMessage.value = 'An error happened.';
  } finally {
    isSubmitting.value = false;
  }
}

const topBlurClass = computed(() =>
  isStagingOrLocal.value ? 'top-10' : 'top-0',
);
const loginFormHeight = computed(() =>
  isStagingOrLocal.value ? 'calc(100vh - 165px)' : 'calc(100vh - 150px)',
);
</script>

<template>
  <div class="flex flex-col" :style="{ height: loginFormHeight }">
    <div
      id="confirm-password-reset-modal"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      data-test-id="confirm-password-reset-modal"
      :class="[
        'fixed inset-x-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm',
        topBlurClass,
      ]"
    >
      <div
        class="w-full max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 backdrop-blur-sm"
      >
        <div class="flex items-center justify-center mb-4">
          <img
            src="@/assets/images/logo.png"
            alt="Marketplace Logo"
            class="h-10"
          />
          <span
            class="text-xl font-semibold text-gray-900 dark:text-white ml-2"
          >
            Marketplace
          </span>
        </div>

        <div v-if="successMessage" class="text-center">
          <p class="text-black font-medium mt-4">{{ successMessage }}</p>

          <FhLink
            v-if="isUserActive"
            variant="primary"
            @click.prevent="router.push({ name: 'login' })"
            data-test-id="back-to-login-link"
            href="#"
            class="mt-2 inline-block"
          >
            Go to login
          </FhLink>
        </div>

        <template v-else>
          <h1 class="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Welcome to FareHarbor Marketplace!
          </h1>
          <p class="text-gray-700 dark:text-gray-300 leading-relaxed mb-4">
            We are thrilled to introduce
            <strong>FareHarbor Marketplace</strong>
            , the next evolution of the FareHarbor Distribution Network.
          </p>

          <p class="text-gray-700 dark:text-gray-300 leading-relaxed mb-4">
            We&apos;re transitioning from the Directory to Marketplace to offer
            a flexible, modern, and user-friendly experience.
          </p>

          <p class="text-gray-700 dark:text-gray-300 leading-relaxed mb-4">
            Moving from the Directory to Marketplace is seamless, and we&apos;re
            here to support you every step of the way.
          </p>

          <div class="mt-4 text-center">
            <FhButton
              :disabled="isSubmitting"
              :loading="isSubmitting"
              type="button"
              fullWidth
              data-test-id="accept-invitation-button"
              class="mt-2"
              @click="acceptInvitation"
            >
              Accept invitation
            </FhButton>

            <p v-if="errorMessage" class="text-red-600 mt-4">
              {{ errorMessage }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
