<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import '@fareharbor-com/beacon-vue/fonts/roboto';
import {
  fareharborLightTheme,
  useTheme,
} from '@fareharbor-com/beacon-vue/themes';
import { initFlowbite } from 'flowbite';
import DemoBanner from '@/components/DemoBanner.vue';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useAuthStore } from '@/stores/useAuthStore';

useTheme(fareharborLightTheme);

onMounted(async () => {
  initFlowbite();
});

const authStore = useAuthStore();
const router = useRouter();

watch(
  () => authStore.isAuthenticated,
  (newIsAuthenticated, oldIsAuthenticated) => {
    if (!newIsAuthenticated && oldIsAuthenticated) {
      router.push({ name: 'login' });
    }
  },
);
</script>

<template>
  <BaseFlexbox
    direction="column"
    gap="0"
    justifyContent="normal"
    alignItems="stretch"
    class="min-h-screen"
  >
    <DemoBanner />
    <MainHeader />
    <RouterView :key="$route.fullPath" />
    <MainFooter />
  </BaseFlexbox>

  <ToastContainer />
</template>
