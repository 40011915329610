import { Logger } from '@/common/Logger';
import { postToken } from '@/common/api/postToken';
import { postTokenRefresh } from '@/common/api/postTokenRefresh';
import { localCache } from '@/common/cacheService';
import { FORBIDDEN, OK, UNAUTHORIZED } from '@/common/constants/status';
import { cookies } from '@/common/cookies';

type PostTokenResponsePromise = ReturnType<typeof postToken>;

/**
 * Service for managing authentication tokens (access and refresh tokens)
 * in browser cookies with cross-tab synchronization support.
 */
export interface AuthTokenService {
  /** Removes both access and refresh tokens from cookies. */
  clearTokens(): void;

  /** Retrieves the current access token from cookies. */
  getAccessToken(): string | undefined;

  /** Retrieves the current refresh token from cookies. */
  getRefreshToken(): string | undefined;

  /** Checks if both access and refresh tokens are present in cookies. */
  hasTokens(): boolean;

  /**
   * Authenticates user credentials and stores received tokens
   * @param username - User's login identifier
   * @param password - User's password
   * @returns Promise with token request response
   *
   * This function will clear the tokens if the credentials are invalid.
   *
   * @todo It is worth to fully encapsulate network calls and return
   * something more meaningful to the caller instead of a raw HTTP response.
   */
  obtainTokens(username: string, password: string): PostTokenResponsePromise;

  /**
   * Attempts to refresh the access token using the current refresh token
   * @returns Promise resolving to true if refresh was successful, false otherwise
   */
  refreshAccessToken(): Promise<boolean>;
}

// Cookie keys for tokens.

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

// Token management functions.

function clearTokens(): void {
  cookies.unset(ACCESS_TOKEN_KEY);
  cookies.unset(REFRESH_TOKEN_KEY);
}

function getAccessToken(): string | undefined {
  return cookies.get(ACCESS_TOKEN_KEY);
}

function getRefreshToken(): string | undefined {
  return cookies.get(REFRESH_TOKEN_KEY);
}

function setAccessToken(accessToken: string): void {
  cookies.set(ACCESS_TOKEN_KEY, accessToken);
}

function setRefreshToken(refreshToken: string): void {
  cookies.set(REFRESH_TOKEN_KEY, refreshToken);
}

function hasTokens(): boolean {
  return getAccessToken() !== undefined && getRefreshToken() !== undefined;
}

// One place to describe the response statuses for bad credentials.
function isBadCredentialsStatus(status: number) {
  return status === FORBIDDEN || status === UNAUTHORIZED;
}

// Obtain tokens from the server.
async function obtainTokens(
  username: string,
  password: string,
): PostTokenResponsePromise {
  const response = await postToken(username, password);
  if (response.status === OK && response.data) {
    const { access, refresh } = response.data;
    setAccessToken(access);
    setRefreshToken(refresh);
  } else if (isBadCredentialsStatus(response.status)) {
    // Clear tokens in case of unsuccessful refresh only.
    clearTokens();
  }
  return response;
}

// Local storage key for the refresh lock.
const REFRESH_LOCK_KEY = 'refresh_lock';

/**
 * Try to set the refresh lock.
 * @returns true if the lock was set, false otherwise.
 */
function trySetRefreshLock(): boolean {
  const isLocked = localCache.getItem(REFRESH_LOCK_KEY);
  if (isLocked) {
    // Refresh is already in progress,
    // attempt to set the lock is futile.
    return false;
  }
  // Set the lock and report success.
  localCache.setItem(REFRESH_LOCK_KEY, true);
  return true;
}

function releaseRefreshLock() {
  localCache.removeItem(REFRESH_LOCK_KEY);
}

// List of callbacks to notify when the refresh is complete.
const waitingRefreshers: ((isRefreshed: boolean) => void)[] = [];

// Flush the list of callbacks and fan out the result.
function notifyWaitingRefreshers(isRefreshed: boolean) {
  waitingRefreshers.forEach((callback) => {
    try {
      callback(isRefreshed);
    } catch (error) {
      Logger.error(error);
    }
  });
  waitingRefreshers.length = 0;
}

// Listen for changes in the local storage to detect when the
// refresh lock is released from another tab.
window.addEventListener('storage', (event: StorageEvent) => {
  if (event.key === REFRESH_LOCK_KEY && event.newValue === null) {
    // Refresh lock was released, notify waiting refreshers.
    const isRefreshed = getAccessToken() !== undefined;
    notifyWaitingRefreshers(isRefreshed);
  }
});

// Refresh the access token or wait for the ongoing refresh to complete.
async function refreshAccessToken(): Promise<boolean> {
  const refreshToken = getRefreshToken();
  // Early return if no refresh token available.
  if (refreshToken === undefined) {
    return false;
  }
  // Attempt to set the lock for the refresh operation.
  if (trySetRefreshLock()) {
    // Successfully set the lock.
    let isRefreshed = false;
    try {
      const response = await postTokenRefresh(refreshToken);
      if (response.status === OK && response.data) {
        // Successfully refreshed the access token.
        setAccessToken(response.data.access);
        isRefreshed = true;
      } else if (isBadCredentialsStatus(response.status)) {
        // Clear tokens before releasing the lock to ensure
        // other tabs can detect the refresh failure.
        clearTokens();
      }
      // Server or network errors fall through as it still makes sense
      // to try again with the same refresh token.
    } finally {
      // Release the lock and notify waiting refreshers.
      releaseRefreshLock();
      notifyWaitingRefreshers(isRefreshed);
    }
    return isRefreshed;
  }
  // The lock is already set by another caller,
  // wait for the lock to be released.
  return new Promise<boolean>((resolve) => {
    waitingRefreshers.push(resolve);
  });
}

// Export what's public.
export const authTokenService: AuthTokenService = {
  clearTokens,
  getAccessToken,
  getRefreshToken,
  hasTokens,
  obtainTokens,
  refreshAccessToken,
};
