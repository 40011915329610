<script setup lang="ts">
import { computed } from 'vue';
import { FhText } from '@fareharbor-com/beacon-vue';
import { config } from '@/configs';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const currentEnvironment = config.ENVIRONMENT;

const isStagingOrLocal = computed(() =>
  [InventoryEnvironment.STAGING, InventoryEnvironment.LOCAL].includes(
    currentEnvironment,
  ),
);

const environmentName = computed(() => {
  if (currentEnvironment === InventoryEnvironment.STAGING) {
    return 'Staging';
  }
  if (currentEnvironment === InventoryEnvironment.LOCAL) {
    return 'Local';
  }
  return '';
});
</script>

<template>
  <div
    v-if="isStagingOrLocal"
    class="demo-banner w-full text-sm text-white text-center p-2 z-999"
  >
    <FhText variant="body-lg" color="inverted">
      {{ environmentName }} Application - For Testing Purposes Only
    </FhText>
  </div>
</template>

<style scoped>
.demo-banner {
  background-color: #c84801;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
