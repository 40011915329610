import { inject, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Logger } from '@/common/Logger';
import { OK } from '@/common/constants/status';
import { handleThrownError } from '@/common/errors/handleThrownError';
import { formErrorParser } from '@/common/formErrorParser';
import type { TrackingService } from '@/common/tracking/TrackingService';
import { trackingKey } from '@/plugins/tracking';
import { useAuthStore } from '@/stores/useAuthStore';
import { useUserStore } from '@/stores/user';

export const useLogin = () => {
  const router = useRouter();
  const authStore = useAuthStore();
  const userStore = useUserStore();

  const form = reactive({
    username: '',
    password: '',
  });

  const errors: string[] = reactive([]);
  const isLoading = ref(false);

  const tracking = inject<TrackingService>(trackingKey);

  const validateForm = () => {
    errors.splice(0, errors.length);

    if (!form.username || !form.password) {
      errors.push('username or password are required!');
    }
    return errors.length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      Logger.debug('Validation errors');
      return;
    }

    try {
      isLoading.value = true;

      const response: any = await authStore.obtainTokens(
        form.username,
        form.password,
      );

      if (response.status === OK) {
        await userStore.fetchUserData();
        if (!userStore.user) {
          Logger.info('Logged in successfully but user data is missing');
        } else {
          Logger.debug(`
            ${response.status}
            'Logged in successfully as: '
            ${userStore.user}`);
        }

        tracking?.trackLogin({
          isAdmin: userStore.user?.user.isStaff ?? false,
          affiliateShortname: userStore.user?.company.shortName,
        });

        const { loginOptions } = authStore;
        if (loginOptions?.next) {
          router.push(loginOptions.next);
        } else {
          router.push({ name: 'item-list' });
        }
      } else {
        errors.push('Incorrect username or password.');
        const parsedErrors = formErrorParser(response.status, response.data);
        Logger.debug(`Login failure: ${parsedErrors}`);
      }
    } catch (error) {
      handleThrownError({
        error,
        toastMessage:
          'A network error occurred. Please check your connection and try again.',
        log: true,
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    form,
    isLoading,
    errors,
    handleSubmit,
  };
};
