import { captureException, captureMessage } from '@sentry/vue';
import {
  addContextToMessage,
  type MessageContext,
} from '@/common/addContextToMessage';
import { isSentryEnabledOnCurrentEnvironment } from '@/common/sentry/isSentryEnabledOnCurrentEnvironment';
import { config } from '@/configs';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const LOG_TO_CONSOLE =
  config.LOG_ERRORS && config.ENVIRONMENT !== InventoryEnvironment.PRODUCTION;

export class Logger {
  static error(error: unknown): void {
    if (isSentryEnabledOnCurrentEnvironment) {
      captureException(error);
    }
    if (LOG_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  static info(message: string, context: MessageContext = {}): void {
    const messageContext = {
      ...context,
    };

    const devMode = localStorage.getItem('dev-mode') === 'true';

    const messageWithContext = addContextToMessage(message, messageContext);

    if (isSentryEnabledOnCurrentEnvironment && devMode) {
      captureMessage(messageWithContext);
    }
    if (LOG_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(messageWithContext);
    }
  }

  static debug(message: string, context: MessageContext = {}): void {
    const messageContext = {
      ...context,
    };

    const messageWithContext = addContextToMessage(message, messageContext);
    if (LOG_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(messageWithContext);
    }
  }
}
