import { cookies } from '@/common/cookies';

export const authorizationHeaders = () => {
  const accessToken = cookies.get('access_token');

  let headers = {};

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return headers;
};
