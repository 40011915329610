import type { RouteLocationNormalized } from 'vue-router';
import { useAuthStore } from '@/stores/useAuthStore';

/**
 * Guard to ensure the user is authenticated.
 */
export function guardUnauthenticated(to: RouteLocationNormalized) {
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated) {
    authStore.setLoginOptions({ next: to });
    return { name: 'login' };
  }

  return true;
}

/**
 * Guard to validate the 'page' query parameter.
 */
export function guardBadPageQueryParam(to: RouteLocationNormalized) {
  const queryPage = to.query.page;

  if (queryPage !== undefined) {
    if (
      Array.isArray(queryPage) ||
      queryPage === null ||
      Number.isNaN(Number.parseInt(queryPage as string, 10))
    ) {
      const targetWithoutPage = { ...to, query: { ...to.query } };
      delete targetWithoutPage.query.page;
      return targetWithoutPage;
    }
  }

  return true;
}

/**
 * Guard to remove empty query parameters.
 */
export function guardEmptyQueryParams(to: RouteLocationNormalized) {
  const targetWithoutEmptyQueryParams = { ...to, query: { ...to.query } };
  let isAltered = false;

  Object.entries(to.query).forEach(([key, value]) => {
    if (value === '') {
      delete targetWithoutEmptyQueryParams.query[key];
      isAltered = true;
    }
  });

  if (isAltered) {
    return targetWithoutEmptyQueryParams;
  }

  return true;
}
