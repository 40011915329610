import { InventoryEnvironment } from '@/types/InventoryEnvironment';
import { parseInventoryEnvironment } from './envUtils';

export interface EnvironmentConfig {
  readonly BACKEND_BASE_URL: string;
  readonly IGNORE_SESSION_STORAGE: boolean;
  readonly ENVIRONMENT: InventoryEnvironment;
  readonly COMMIT_ID?: string;
  readonly SENTRY_DSN?: string;
  readonly SENTRY_SAMPLING_RATE?: number;
  readonly MIXPANEL_TOKEN?: string;
  readonly LOG_ERRORS?: boolean;
}

export const config: EnvironmentConfig = {
  BACKEND_BASE_URL: import.meta.env.VITE_BACKEND_BASE_URL,
  IGNORE_SESSION_STORAGE:
    import.meta.env.VITE_IGNORE_SESSION_STORAGE === 'true',
  ENVIRONMENT: parseInventoryEnvironment(import.meta.env.VITE_ENVIRONMENT),
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  COMMIT_ID: import.meta.env.VITE_COMMIT_ID,
  SENTRY_SAMPLING_RATE: import.meta.env.VITE_SENTRY_SAMPLING_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_SAMPLING_RATE)
    : undefined,
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
  LOG_ERRORS: !!import.meta.env.VITE_LOG_ERRORS,
};

const BASE_API_URL: string = `${config.BACKEND_BASE_URL}/api`;

export const API_URLS = {
  BASE: BASE_API_URL,
  TOKEN: `${BASE_API_URL}/token/`,
  TOKEN_REFRESH: `${BASE_API_URL}/token/refresh/`,
};
