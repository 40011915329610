import { API_URLS } from '@/configs';
import type { ItemRatingPayload } from '@/types';
import { fetchData } from '.';
import { type GenericResponse } from './base';

export type GetItemRatingResponse = GenericResponse<ItemRatingPayload>;

export const getItemRating = (
  shortname: string,
  itemPk: number,
): Promise<GetItemRatingResponse> => {
  const url = new URL(`${API_URLS.BASE}/item-rating/${shortname}/${itemPk}/`);
  try {
    return fetchData(url);
  } catch (error) {
    // We send multiple parallel requests to fetch item ratings independently, allowing
    // data to be displayed to the user incrementally rather than waiting for all responses.
    // However, if the server receives too many simultaneous requests, it may start rejecting
    // them due to resource exhaustion. In such cases, the automatic denial responses lack
    // CORS headers, leading to a `TypeError`.
    //
    // This is a temporary workaround; the rating retrieval mechanism needs improvement.
    return Promise.resolve({ error, status: 0 });
  }
};
