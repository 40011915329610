import { API_URLS } from '@/configs';
import type { ItemAvailabilityCountPayload } from '@/types';
import { fetchData } from '.';
import { type GenericResponse } from './base';

export type GetItemAvailabilityCountResponse =
  GenericResponse<ItemAvailabilityCountPayload>;

export const getItemAvailabilityCount = (
  itemPk: number,
): Promise<GetItemAvailabilityCountResponse> => {
  const url = new URL(`${API_URLS.BASE}/item-availability-count/${itemPk}/`);
  return fetchData(url);
};
