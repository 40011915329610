<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { FhButton, FhLink } from '@fareharbor-com/beacon-vue';
import { apiRequest } from '@/common/apiRequest';
import DeskForm from '@/components/shared/DeskForm/DeskForm.vue';
import DeskInput from '@/components/shared/DeskInput/DeskInput.vue';
import { API_URLS, config } from '@/configs';
import { HTTP_METHOD } from '@/types/HttpMethods';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const currentEnvironment = config.ENVIRONMENT;
const isStagingOrLocal = computed(() =>
  [InventoryEnvironment.STAGING, InventoryEnvironment.LOCAL].includes(
    currentEnvironment,
  ),
);

const router = useRouter();
const email = ref('');
const isSubmitting = ref(false);
const successMessage = ref('');
const errorMessage = ref('');
const requestSent = ref(false);

async function requestPasswordReset() {
  isSubmitting.value = true;
  successMessage.value = '';
  errorMessage.value = '';

  try {
    const url = `${API_URLS.BASE}/request-password-reset/`;
    await apiRequest({
      url,
      method: HTTP_METHOD.POST,
      body: { email: email.value },
    });

    requestSent.value = true;
    successMessage.value =
      'If an account with this email exists, you will receive a password reset email.';
  } catch (error) {
    errorMessage.value =
      'An error occurred. Please check your email and try again.';
  } finally {
    isSubmitting.value = false;
  }
}

const topBlurClass = computed(() =>
  isStagingOrLocal.value ? 'top-10' : 'top-0',
);
const loginFormHeight = computed(() =>
  isStagingOrLocal.value ? 'calc(100vh - 165px)' : 'calc(100vh - 150px)',
);
</script>

<template>
  <div class="flex flex-col" :style="{ height: loginFormHeight }">
    <div
      id="password-reset-modal"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      data-test-id="password-reset-modal"
      :class="[
        'fixed inset-x-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm',
        topBlurClass,
      ]"
    >
      <div
        class="relative w-full max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300"
      >
        <div class="flex items-center justify-center mb-4">
          <img
            src="@/assets/images/logo.png"
            alt="Marketplace Logo"
            class="h-10"
          />
          <span
            class="text-xl font-semibold text-gray-900 dark:text-white ml-2"
          >
            Marketplace
          </span>
        </div>

        <div v-if="!requestSent">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Request password reset
          </h3>
          <DeskForm
            form-name="Password Reset Form"
            submit-text="Send Reset Link"
            :errors="[errorMessage]"
            :is-submitting="isSubmitting"
            :is-valid="!!email"
            @submit.prevent="requestPasswordReset"
            data-test-id="password-reset-form"
          >
            <DeskInput
              class="mb-3"
              type="email"
              placeholder="Email"
              v-model="email"
              autocomplete="email"
              dataTestId="password-reset-email"
            />
            <template #form-actions>
              <FhButton
                :disabled="isSubmitting"
                :loading="isSubmitting"
                type="submit"
                fullWidth
                data-test-id="password-reset-button"
                class="mt-2"
              >
                Send password reset link
              </FhButton>
            </template>
          </DeskForm>
        </div>

        <div v-if="requestSent" class="text-left">
          <p class="text-black text-lg font-medium">{{ successMessage }}</p>
        </div>

        <div class="p-4 text-left">
          <FhLink
            variant="primary"
            @click.prevent="router.push({ name: 'login' })"
            data-test-id="back-to-login-link"
            href="#"
          >
            Back to login
          </FhLink>
        </div>
      </div>
    </div>
  </div>
</template>
