import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { handleThrownError } from '@/common/errors/handleThrownError';
import { initializeSentry } from '@/common/sentry/initializeSentry';
import tracking from '@/plugins/tracking';
import router from '@/router';
import { guardUnauthenticated } from '@/router/guards';
import { setRouteErrorHandler } from '@/router/setRouteErrorHandler';
import { useAuthStore } from '@/stores/useAuthStore';
import { useFeatureFlagStore } from '@/stores/useFeatureFlagStore';
import App from './App.vue';
import './assets/style/main.css';
import './index.css';

setRouteErrorHandler(router);

const app = createApp(App);

app.use(createPinia());
app.use(tracking);
app.use(router);

const featureFlagStore = useFeatureFlagStore();
featureFlagStore.loadFlags();

// Initialize Sentry
initializeSentry(app);

const authStore = useAuthStore();

const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 5 - 10_000; // 4 minutes and 50 seconds

async function refreshToken(): Promise<void> {
  try {
    await authStore.doTokenRefresh();
  } catch (error) {
    handleThrownError({
      error,
      log: true,
    });
  }
}

async function initializeApp() {
  // guardUnauthenticated should be called before router.isReady.
  const guardUnauthenticatedResult = guardUnauthenticated(
    router.currentRoute.value,
  );

  await router.isReady();

  const PUBLIC_ROUTES = [
    'request-password-reset',
    'confirm-password-reset',
    'accept-invitation',
  ];

  if (!PUBLIC_ROUTES.includes(router.currentRoute.value.name as string)) {
    if (guardUnauthenticatedResult) {
      await refreshToken();
      setInterval(refreshToken, TOKEN_REFRESH_INTERVAL);
    } else {
      authStore.logout();
    }
  }

  app.mount('#app');
}

initializeApp();
