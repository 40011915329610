<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { FhButton, FhLink } from '@fareharbor-com/beacon-vue';
import DeskForm from '@/components/shared/DeskForm/DeskForm.vue';
import DeskInput from '@/components/shared/DeskInput/DeskInput.vue';
import { API_URLS, config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';
import { HTTP_METHOD } from '@/types/HttpMethods';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const currentEnvironment = config.ENVIRONMENT;

const isStagingOrLocal = computed(() =>
  [InventoryEnvironment.STAGING, InventoryEnvironment.LOCAL].includes(
    currentEnvironment,
  ),
);

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

// Extract UID & Token from the URL
const uid = ref<string | null>(null);
const token = ref<string | null>(null);
const email = ref<string | null>(null);

// Form Data
const newPassword = ref('');
const confirmPassword = ref('');
const isSubmitting = ref(false);
const successMessage = ref('');
const errorMessages = ref<string[]>([]);

onMounted(() => {
  uid.value = (route.query.uid as string) || null;
  token.value = (route.query.token as string) || null;
  email.value = (route.query.email as string) || null;
  const currentRoute = router.currentRoute.value;
  authStore.logout();
  router.push(currentRoute);
});

// Function to submit new password
async function confirmPasswordReset() {
  isSubmitting.value = true;
  successMessage.value = '';
  errorMessages.value = [];

  if (newPassword.value !== confirmPassword.value) {
    errorMessages.value.push('Passwords do not match.');
    isSubmitting.value = false;
    return;
  }

  try {
    const response = await fetch(`${API_URLS.BASE}/confirm-password-reset/`, {
      method: HTTP_METHOD.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: uid.value,
        token: token.value,
        new_password1: newPassword.value,
        new_password2: confirmPassword.value,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      // If the response is not OK, extract error messages from the response
      if (data && typeof data === 'object') {
        errorMessages.value = Object.values(data).flat() as string[];
      } else {
        errorMessages.value = ['An error occurred. Please try again.'];
      }
      return;
    }

    // Success case
    successMessage.value = 'Your password has been successfully reset.';
  } catch (error) {
    errorMessages.value = [
      'An error occurred. Please check your network connection and try again.',
    ];
  } finally {
    isSubmitting.value = false;
  }
}

const topBlurClass = computed(() =>
  isStagingOrLocal.value ? 'top-10' : 'top-0',
);

const loginFormHeight = computed(() =>
  isStagingOrLocal.value ? 'calc(100vh - 165px)' : 'calc(100vh - 150px)',
);
</script>

<template>
  <div class="flex flex-col" :style="{ height: loginFormHeight }">
    <div
      id="confirm-password-reset-modal"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      data-test-id="confirm-password-reset-modal"
      :class="[
        'fixed inset-x-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm',
        topBlurClass,
      ]"
    >
      <div
        class="w-full max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 backdrop-blur-sm"
      >
        <!-- Logo + Marketplace Title -->
        <div class="flex items-center justify-center mb-4">
          <img
            src="@/assets/images/logo.png"
            alt="Marketplace Logo"
            class="h-10"
          />
          <span
            class="text-xl font-semibold text-gray-900 dark:text-white ml-2"
          >
            Marketplace
          </span>
        </div>

        <!-- Title & Subtitle (Disappear upon success) -->
        <div v-if="!successMessage">
          <h3
            class="text-xl font-semibold text-gray-900 dark:text-white text-center"
          >
            Reset your password
          </h3>
          <p class="text-gray-600 text-sm text-center mt-1">
            Enter your new password below to complete the reset process.
          </p>
        </div>

        <div class="mt-4">
          <DeskForm
            v-if="!successMessage"
            form-name="Confirm Password Reset Form"
            submit-text="Reset Password"
            :errors="errorMessages"
            :is-submitting="isSubmitting"
            :is-valid="!!newPassword && !!confirmPassword"
            @submit.prevent="confirmPasswordReset"
            data-test-id="confirm-password-reset-form"
          >
            <!-- Hidden Email Field to Satisfy Autofill Requirements -->
            <input
              type="email"
              v-model="email"
              autocomplete="username"
              aria-hidden="true"
              class="hidden"
            />

            <!-- Password Field -->
            <label
              for="new-password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <DeskInput
              id="new-password"
              class="mb-3"
              type="password"
              v-model="newPassword"
              autocomplete="new-password"
              dataTestId="confirm-password-new"
            />

            <!-- Confirm Password Field -->
            <label
              for="confirm-password"
              class="block text-sm font-medium text-gray-700"
            >
              Confirm password
            </label>
            <DeskInput
              id="confirm-password"
              class="mb-3"
              type="password"
              v-model="confirmPassword"
              autocomplete="new-password"
              dataTestId="confirm-password-confirm"
            />

            <template #form-actions>
              <FhButton
                :disabled="isSubmitting"
                :loading="isSubmitting"
                type="submit"
                fullWidth
                data-test-id="confirm-password-reset-button"
                class="mt-2"
              >
                Reset password
              </FhButton>
            </template>
          </DeskForm>

          <!-- Show success message after reset -->
          <div v-if="successMessage" class="text-left">
            <p class="text-black text-lg font-medium">{{ successMessage }}</p>
            <FhLink
              variant="primary"
              @click.prevent="router.push({ name: 'login' })"
              data-test-id="back-to-login-link"
              href="#"
              class="mt-2 inline-block"
            >
              Back to login
            </FhLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
