<script setup lang="ts">
import { ref } from 'vue';
import { FhSpinner, FhIcon } from '@fareharbor-com/beacon-vue';
import { bookmark, bookmarkFilled } from '@fareharbor-com/beacon-vue/icons';
import { Logger } from '@/common/Logger';
import { addItemToBookmarks } from '@/common/api/addItemToBookmarks';
import { deleteItemFromBookmarks } from '@/common/api/deleteItemFromBookmarks';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useToastStore } from '@/stores/useToastStore';

interface ItemRow {
  itemId: number;
  isBookmarked: boolean;
}

interface ItemListBookmarkProps {
  row: ItemRow;
}

const props = defineProps<ItemListBookmarkProps>();
const { row } = props;
const toastStore = useToastStore();

const isBookmarkLoading = ref(false);
const bookmarkError = ref<Error | null>(null);

// Initialize isBookmarked with the value from props
const isBookmarked = ref(row.isBookmarked);

const toggleBookmark = async () => {
  if (isBookmarkLoading.value) return;

  isBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    if (isBookmarked.value) {
      const response = await deleteItemFromBookmarks(row.itemId);
      if (response.error) {
        bookmarkError.value = response.error;
        toastStore.error('Error removing bookmark, please try again.');
        Logger.info('Error removing bookmark:', response.error);
      } else {
        toastStore.success('Bookmark removed successfully.');
        isBookmarked.value = false;
      }
    } else {
      const response = await addItemToBookmarks(row.itemId);

      if (response.error) {
        bookmarkError.value = response.error;
        toastStore.error('Error adding bookmark, please try again.');
        Logger.info('Error adding bookmark:', response.error);
      } else {
        toastStore.success('Bookmark added successfully.');
        isBookmarked.value = true;
      }
    }
  } catch (error: unknown) {
    Logger.error(error);

    if (error instanceof Error) {
      bookmarkError.value = error;
      toastStore.error(`Unexpected error: ${error.message}`);
    } else {
      bookmarkError.value = new Error(
        'An unknown error occurred while toggling the bookmark.',
      );
      toastStore.error(
        'An unknown error occurred while toggling the bookmark.',
      );
    }
  } finally {
    isBookmarkLoading.value = false;
  }
};
</script>

<template>
  <BaseFlexbox
    class="px-1 py-3"
    direction="column"
    alignItems="center"
    @click.stop.prevent="toggleBookmark"
    data-test-id="item-bookmark-icon"
  >
    <template v-if="isBookmarkLoading">
      <FhSpinner size="sm" />
    </template>
    <template v-else-if="isBookmarked">
      <FhIcon
        :icon="bookmarkFilled"
        size="md"
        data-test-id="item-is-bookmarked"
      />
    </template>
    <template v-else>
      <FhIcon
        :icon="bookmark"
        size="md"
        data-test-id="item-is-not-bookmarked"
      />
    </template>
  </BaseFlexbox>
</template>
