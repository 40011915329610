import { authorizationHeaders } from '@/common/authorizationHeaders';
import { API_URLS } from '@/configs';
import { authorizedFetch } from './authorizedFetch';
import { type GenericResponse } from './base';

export const deleteItemFromBookmarks = async (
  itemId: number,
): Promise<GenericResponse<null>> => {
  const url = `${API_URLS.BASE}/item-bookmarks/${itemId}/delete/`;

  try {
    const response: Response = await authorizedFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeaders(),
      },
    });

    if (!response.ok) {
      return {
        status: response.status,
        data: null,
        error: new Error(`HTTP error! status: ${response.status}`),
      };
    }

    return {
      status: response.status,
      data: null,
      error: null,
    };
  } catch (err) {
    return {
      status: 500,
      data: null,
      error: err as Error,
    };
  }
};
