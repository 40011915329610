<script setup lang="ts">
import { type Component, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { FhSpinner } from '@fareharbor-com/beacon-vue';
import { storeToRefs } from 'pinia';
import { uniqueId } from '@/common/uniqueId';
import { useDebounce } from '@/composables/useDebounce';
import { useLocationStore } from '@/stores/locations';
import type { LocationSuggestion } from '@/types';
import LocationSearchSuggestion from './LocationSearchSuggestion.vue';

const NO_LOCATIONS_FOUND: LocationSuggestion = {
  city: '',
  state: '',
  country: '',
  matchedFields: ['city'],
  locationType: 4,
};

const DEFAULT_LOCATIONS: LocationSuggestion[] = [
  {
    city: '',
    state: '',
    country: 'United States',
    matchedFields: ['country'],
    locationType: 1,
  },
  {
    city: '',
    state: '',
    country: 'Hawaii',
    matchedFields: ['country'],
    locationType: 1,
  },
  {
    city: '',
    state: '',
    country: 'Puerto Rico',
    matchedFields: ['country'],
    locationType: 1,
  },
  {
    city: '',
    state: '',
    country: 'Canada',
    matchedFields: ['country'],
    locationType: 1,
  },
];

interface SearchBarProps {
  initialText?: string;
  placeholder?: string;
  icon?: Component;
}

const props = defineProps<SearchBarProps>();
const emit = defineEmits(['submitSearch', 'clearSearch']);

const id = uniqueId('search-bar');
const text = ref(props.initialText || '');
const highlightedIndex = ref(-1);
const locationStore = useLocationStore();
const { isLoading } = storeToRefs(locationStore);
const suggestions = ref<LocationSuggestion[]>([]);
const containerRef = ref<HTMLElement | null>(null);

const formatSuggestion = (location: LocationSuggestion): string =>
  [location.city, location.state, location.country].filter(Boolean).join(', ');

// Debounce search input changes to prevent frequent calls
const debouncedSearch = useDebounce((searchText: string) => {
  if (!searchText) {
    suggestions.value = DEFAULT_LOCATIONS;
    highlightedIndex.value = -1;
  } else {
    const result = locationStore.getSuggestions(searchText);
    suggestions.value = result.length > 0 ? result : [NO_LOCATIONS_FOUND];
    highlightedIndex.value = -1;
  }
}, 300);

const handleSearchChange = () => {
  debouncedSearch(text.value);
};

const handleSuggestionClick = (suggestion: LocationSuggestion) => {
  text.value = formatSuggestion(suggestion);
  suggestions.value = [];
  highlightedIndex.value = -1;
  emit('submitSearch', text.value);
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (
    event.key === 'ArrowDown' &&
    highlightedIndex.value < suggestions.value.length - 1
  ) {
    event.preventDefault();
    highlightedIndex.value += 1;
  } else if (event.key === 'ArrowUp' && highlightedIndex.value > 0) {
    event.preventDefault();
    highlightedIndex.value -= 1;
  } else if (event.key === 'Enter' && highlightedIndex.value >= 0) {
    event.preventDefault();
    handleSuggestionClick(suggestions.value[highlightedIndex.value]);
  }
};
// Initialize suggestions to default when focused
const handleFocus = () => {
  if (!text.value) {
    suggestions.value = DEFAULT_LOCATIONS;
    highlightedIndex.value = -1;
  }
};

// **Handle clicks outside the component to hide suggestions**
const handleClickOutside = (event: MouseEvent) => {
  if (
    containerRef.value &&
    !containerRef.value.contains(event.target as Node)
  ) {
    suggestions.value = [];
  }
};

// **Add event listener on mount and remove on unmount**
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

// Watch for input changes and update suggestions accordingly
watch(text, (newText) => {
  if (!newText) {
    suggestions.value = DEFAULT_LOCATIONS;
    highlightedIndex.value = -1;
    emit('clearSearch');
  }
});
</script>

<template>
  <form @submit.prevent="handleSearchChange" class="relative w-full sm:w-auto">
    <div
      ref="containerRef"
      class="flex items-center border border-gray-300 focus-within:border-2 rounded-lg bg-gray-50 focus-within:border-blue-500 w-full"
    >
      <Component :is="icon" class="w-6 h-6 ml-2 text-gray-500" />
      <input
        type="search"
        class="w-[26rem] bg-gray-50 placeholder-gray-400 rounded-lg border-transparent focus:border-transparent focus:ring-0"
        :id="id"
        :placeholder="isLoading ? 'Loading location list' : props.placeholder"
        v-model="text"
        data-test-id="location-search-bar"
        @focus="handleFocus"
        @input="handleSearchChange"
        @keydown="handleKeyDown"
        :disabled="isLoading"
        autocomplete="off"
      />
      <div
        v-if="isLoading"
        class="absolute inset-0 flex items-center justify-end right-3 bg-gray-50 bg-opacity-75"
      >
        <FhSpinner size="md" />
      </div>
    </div>
    <ul
      v-if="suggestions.length > 0"
      class="border border-gray-300 bg-white max-h-64 overflow-y-auto absolute z-50 mt-12 w-full rounded-lg"
    >
      <LocationSearchSuggestion
        v-for="(suggestion, index) in suggestions"
        :key="suggestion.city + suggestion.state + suggestion.country"
        :suggestion="suggestion"
        :prefix="text"
        @click="handleSuggestionClick(suggestion)"
        :class="{
          'p-3 cursor-pointer': true,
          'bg-gray-200': index === highlightedIndex,
        }"
        :aria-selected="index === highlightedIndex"
      />
    </ul>
  </form>
</template>

<style scoped>
.sb-border-width {
  border-width: 1px;
}
</style>
